import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import GAuth from 'vue3-google-oauth2'

// 套版
// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../src/assets/scss/bootstrap/bootstrap.scss'
import '../src/assets/css/plugins/icofont.min.css'
import '../src/assets/css/plugins/animate.css'
import '../src/assets/scss/style.scss'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

// 9skin
import '../public/assets/css/mainoption.css'
import '../public/assets/css/siteNewClass.css'
import '../public/assets/css/swiper-style.css'
import '../public/assets/css/ckedit5.css'
import '../public/assets/css/swal.css'
import '../public/assets/js/mainOption.js'
import { myMixin } from '../public/assets/js/swal.js'

// 功能
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css
import VueClipboard from 'vue3-clipboard' // 複製
// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faUserSecret,
  faHeart,
  faPlus,
  faCartShopping,
  faMinus,
  faLocationDot,
  faPhone,
  faEnvelope,
  faBars,
  faMagnifyingGlass,
  faUser,
  faChevronRight,
  faIdCard,
  faMedal,
  faCirclePlay,
  faPenToSquare,
  faKey,
  faMapLocationDot,
  faChevronDown,
  faCircleMinus,
  faShareNodes,
  faLink,
  faCircleCheck,
  faMoneyBill,
  faBan,
  faHandHoldingDollar,
  faDolly,
  faCheck,
  faXmark,
  faTrashCan,
  faBox,
  faUserPen,
  faMobileButton,
  faMapLocation,
  faScaleBalanced,
  faQrcode,
  faCircleUser,
  faUserTag,
  faMoneyCheck,
  faBuildingColumns,
  faUserGraduate,
  faCircleQuestion,
  faIdCardClip,
  faUsers,
  faUserFriends,
  faPlay,
  faHandPointRight,
  faInfoCircle,
  faCaretRight,
  faAddressBook,
  faChessQueen,
  faUserTie,
  faSquare,
  faAnglesUp,
  faBell,
  faPhoneVolume,
  faLock,
  faLockOpen,
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons'

import {
  faHeart as faHeartR,
  faTrashCan as faTrashCanR,
  faBell as  faBellR
} from '@fortawesome/free-regular-svg-icons'

import {
  faJenkins,
  faLine,
  faSquareFacebook,
  faSquareInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
library.add(
  faUserSecret,
  faPlus,
  faHeart,
  faCartShopping,
  faMinus,
  faLocationDot,
  faPhone,
  faEnvelope,
  faBars,
  faMagnifyingGlass,
  faUser,
  faChevronRight,
  faIdCard,
  faMedal,
  faCirclePlay,
  faPenToSquare,
  faKey,
  faMapLocationDot,
  faChevronDown,
  faCircleMinus,
  faTrashCan,
  faTrashCanR,
  faShareNodes,
  faLink,
  faCircleCheck,
  faMoneyBill,
  faBan,
  faHandHoldingDollar,
  faDolly,
  faCheck,
  faXmark,
  faBox,
  faHeartR,
  faUserPen,
  faMobileButton,
  faMapLocation,
  faScaleBalanced,
  faQrcode,
  faCircleUser,
  faUserTag,
  faMoneyCheck,
  faBuildingColumns,
  faUserGraduate,
  faCircleQuestion,
  faIdCardClip,
  faLine,
  faSquareFacebook,
  faSquareInstagram,
  faUsers,
  faUserFriends,
  faPlay,
  faHandPointRight,
  faInfoCircle,
  faCaretRight,
  faAddressBook,
  faChessQueen,
  faMedal,
  faUserTie,
  faJenkins,
  faSquare,
  faAnglesUp,
  faBell,
  faBellR,
  faYoutube,
  faPhoneVolume,
  faLock,
  faLockOpen,
  faCircleXmark
)

axios.defaults.baseURL = process.env.VUE_APP_API_URL // 設置axios的基礎請求路徑

const gauthOption = {
  clientId: '246112881374-smr413qs9uufmg8f4jvtcq2h8siea8qg.apps.googleusercontent.com',
  scope: 'profile email openid',
  prompt: 'select_account',
  fetch_basic_profile: false,
  plugin_name: 'chat'
}

const siteClass = {
  container: '...',
  popup: 'site-popup',
  header: '...',
  title: 'site-title',
  closeButton: 'site-close',
  icon: 'site-icon',
  image: '...',
  content: '...',
  htmlContainer: 'site-html-container',
  input: '...',
  inputLabel: '...',
  validationMessage: '...',
  actions: '...',
  cancelButton: 'site-cancel',
  confirmButton: 'site-confirm',
  denyButton: '',
  loader: '...',
  footer: '...'
}

const infoClass = {
  container: '...',
  popup: 'info-popup',
  header: '...',
  title: 'info-title',
  closeButton: 'info-close',
  icon: 'info-icon',
  image: '...',
  content: '...',
  htmlContainer: 'info-html-container',
  input: '...',
  inputLabel: '...',
  validationMessage: '...',
  actions: '...',
  cancelButton: 'info-cancel',
  confirmButton: 'info-confirm',
  denyButton: '',
  loader: '...',
  footer: '...'
}
const app = createApp(App)
app.config.devtools = true
app.config.globalProperties.$siteClass = siteClass
app.config.globalProperties.$infoClass = infoClass
app.config.globalProperties.$siteUrl = process.env.VUE_APP_API_URL
app.component('font-awesome-icon', FontAwesomeIcon)
  .component('v-select', vSelect)
  .mixin(myMixin)
  .use(VueAxios, axios)
  .use(VueSweetalert2)
  .use(GAuth, gauthOption)
  .use(vue3videoPlay)
  .use(VueClipboard,{
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(store)
  .use(router)
  .mount('#app')
